import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      YOU_HAVE_CREATED_A_FLEXITARIAN_MEALBOX:
        "Du har laget en fleksitariansk matkasse.",
      YOU_HAVE_CREATED_A_VEGETARIAN_MEALBOX:
        "Du har laget en vegetarisk matkasse.",
      YOU_HAVE_CREATED_A_PESCETARIAN_MEALBOX:
        "Du har laget en pescetariansk matkasse.",
      YOU_HAVE_CREATED_A_VEGAN_MEALBOX: "Du har laget en vegansk matkasse.",
      HEADS_UP: "Husk!",
      THE_MORE_INGREDIENTS_YOU_REMOVE_THE_FEWER_DISHES_WE_CAN_SUGGEST:
        "Jo flere ingredienser du velger bort, jo færre retter kan vi foreslå.",
      YOU_KNOW_WHAT_YOU_WANT: "Du vet hva du vil ha!",
      WE_WILL_DO_OUR_BEST_TO_MATCH_YOUR_NEEDS: (dishCount: number) =>
        `Vi vil gjøre vårt beste for å oppfylle dine preferanser, men husk at du fritt kan velge blant ${dishCount} retter.`,
      WE_WILL_NOT_BE_ABLE_TO_MATCH_YOUR_PREFERENCES:
        "Vi kan ikke alltid oppfylle alle dine preferanser.",
      WE_WILL_DO_OUR_BEST_BUT_STRONGLY_RECOMMEND_THAT_YOU_CHOOSE_YOUR_OWN_DISHES:
        "Vi gjør selvfølgelig vårt beste, men anbefaler at du velger retter selv for best resultat.",
    },
    se: {
      YOU_HAVE_CREATED_A_FLEXITARIAN_MEALBOX:
        "Du har skapat en flexitarisk matkasse.",
      YOU_HAVE_CREATED_A_VEGETARIAN_MEALBOX:
        "Du har skapat en vegetarisk matkasse.",
      YOU_HAVE_CREATED_A_PESCETARIAN_MEALBOX:
        "Du har skapat en pescetarisk matkasse.",
      YOU_HAVE_CREATED_A_VEGAN_MEALBOX: "Du har skapat en vegansk matkasse.",
      HEADS_UP: "Tips!",
      THE_MORE_INGREDIENTS_YOU_REMOVE_THE_FEWER_DISHES_WE_CAN_SUGGEST:
        "Ju fler ingredienser du tar bort, desto färre rätter kan vi föreslå.",
      YOU_KNOW_WHAT_YOU_WANT: "Du vet vad du vill ha!",
      WE_WILL_DO_OUR_BEST_TO_MATCH_YOUR_NEEDS: (dishCount: number) =>
        `Vi gör vårt bästa för att matcha dina behov. Du kan alltid välja fritt från över ${dishCount} rätter.`,
      WE_WILL_NOT_BE_ABLE_TO_MATCH_YOUR_PREFERENCES:
        "Vi kan inte matcha alla dina preferenser",
      WE_WILL_DO_OUR_BEST_BUT_STRONGLY_RECOMMEND_THAT_YOU_CHOOSE_YOUR_OWN_DISHES:
        "Vi gör vårt bästa, men rekommenderar att du väljer dina rätter själv för bästa resultat.",
    },
    dk: {
      YOU_HAVE_CREATED_A_FLEXITARIAN_MEALBOX:
        "Du har skræddersyet din måltidskasse, så den er flexitarisk.",
      YOU_HAVE_CREATED_A_VEGETARIAN_MEALBOX:
        "Du har skræddersyet din måltidskasse, så den er vegetarisk.",
      YOU_HAVE_CREATED_A_PESCETARIAN_MEALBOX:
        "Du har skræddersyet din måltidskasse, så den er pescetarisk.",
      YOU_HAVE_CREATED_A_VEGAN_MEALBOX:
        "Du har skræddersyet din måltidskasse, så den er vegansk.",
      HEADS_UP: "Tip!",
      THE_MORE_INGREDIENTS_YOU_REMOVE_THE_FEWER_DISHES_WE_CAN_SUGGEST:
        "Jo flere ingredienser du fravælger, jo færre retter vil vi kunne foreslå.",
      YOU_KNOW_WHAT_YOU_WANT: "Du ved, hvad du vil ha!",
      WE_WILL_DO_OUR_BEST_TO_MATCH_YOUR_NEEDS: (dishCount: number) =>
        `Vi vil gøre vores bedste for at matche dine præferencer, men husk at du frit kan vælge mellem ${dishCount} retter.`,
      WE_WILL_NOT_BE_ABLE_TO_MATCH_YOUR_PREFERENCES:
        "Vi kan ikke matche alle dine præferencer.",
      WE_WILL_DO_OUR_BEST_BUT_STRONGLY_RECOMMEND_THAT_YOU_CHOOSE_YOUR_OWN_DISHES:
        "Vi vil selvfølgelig gøre vores bedste, men anbefaler, at du selv vælger dine retter for det bedste resultat.",
    },
  } as const
)[language];
